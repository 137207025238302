import { useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, Col, Form, FormInstance, Input, Row, Select, message } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { AddSender, SenderDetailsActions, SenderDetailsSelector, UpdateSender } from '@features/SenderDetails/';
import { Constants, Roles } from 'common/constants';
import { AUStateCodeList, getLocationDetailsByPostcode } from 'common/code/AUPostalCode';
import { OperationStatus } from 'store/rootTypes';
import { SenderModel } from 'common/models';
import { KFSSignupCustomer } from '@features/Account';

const { Search } = Input;

interface SenderAddressCommonProps {
  header?: string;
  onBack: string;
  onNext: string;
  loadingStatus?: OperationStatus;
  senderModel?: SenderModel;
  buttonNames?: {
    prevButton?: string;
    nextButton?: string;
  };
  role?: string;
  userId?: string;
}

export default function SenderAddressCommon({
  header,
  onBack,
  onNext,
  loadingStatus,
  senderModel,
  buttonNames,
  role,
  userId,
}: SenderAddressCommonProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [suburbError, setSuburbError] = useState(Constants.REQUIRED_FIELD);

  const onFinish = async (values: any) => {
    const models: SenderModel = {
      ...senderModel,
      ...values,
    };

    if (models.Id) {
      await dispatch(UpdateSender(models));
      message.success('Sender updated successfully');
    } else {
      if (role == Roles.Customer) {
        await dispatch(AddSender(models));
      } else {
        if (userId) {
          await dispatch(KFSSignupCustomer(models, userId));
        } else {
          await dispatch(KFSSignupCustomer(models));
        }
      }
      message.success('Sender added successfully');
    }
    await dispatch(SenderDetailsActions.SetSender(models));
    history.push(onNext);
  };
  const [dataSource, setDataSource] = useState<string[]>([]);
  const [dataSourceOriginal, setDataSourceOriginal] = useState<string[]>([]);

  const [state1, setState1] = useState<string | null>(senderModel?.State ?? null);
  const formRef = useRef<FormInstance>(null);

  const onSearch = (value: string) => {
    if (formRef.current) {
      formRef.current.setFieldsValue({ Suburb: '' });
    }
    if (!value) {
      setError('This field is required');
      return;
    }
    const results = getLocationDetailsByPostcode(Number(value));
    if (!results) {
      setError('Not found, please input the address manually.');
      return;
    }

    const uniqueSuburbs = Array.from(new Set(results.map((result) => result.place_name)));
    const stat = Array.from(new Set(results.map((result) => result.state_code)));

    setState1(stat[0]);
    setDataSourceOriginal(uniqueSuburbs);
    setDataSource(uniqueSuburbs);
    setError('');
    if (uniqueSuburbs && uniqueSuburbs.length > 0) {
      if (formRef.current) {
        formRef.current.getFieldInstance('Suburb').focus();
        formRef.current.setFields([{ name: 'Suburb', errors: ['Please enter suburb'] }]);
      }
    } else {
      if (formRef.current) {
        formRef.current.getFieldInstance('Suburb').focus();
        formRef.current.setFields([
          { name: 'Suburb', errors: ['Postal code not found. Please enter address manually.'] },
        ]);
      }
    }
  };

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;

    if (value) {
      setError('');
    } else {
      setError('This field is required');
      return;
    }
  };

  const [selectedValue, setSelectedValue] = useState('');

  const handleSearch = (value) => {
    const filteredData = dataSource.filter((item) => item.toLowerCase().includes(value.toLowerCase()));
    setDataSource(filteredData);
  };

  const onSelect = (value) => {
    setSelectedValue(value);
  };

  const onClear = () => {
    setDataSource(dataSourceOriginal);
  };

  const onFocus = () => {
    setDataSource(dataSourceOriginal);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' || e.target.value.trim() === '') {
      setDataSource(dataSourceOriginal);
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ State: state1 });
  }, [state1]);

  return (
    <div className="App">
      <Row justify="center" align="middle">
        <h1>{header}</h1>
      </Row>

      <Form ref={formRef} name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.Item
              initialValue={senderModel?.Address}
              label="Address"
              name="Address"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Input size="large" maxLength={100} />
            </Form.Item>
            <Form.Item
              initialValue={senderModel?.PostalCode}
              label="Postal code"
              name="PostalCode"
              validateStatus={error ? 'error' : ''}
              help={error}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Search maxLength={10} enterButton="Search" size="large" onSearch={onSearch} onChange={handleChange} />
            </Form.Item>

            <Form.Item
              initialValue={senderModel?.Suburb}
              label="Suburb"
              name="Suburb"
              rules={[{ required: true, message: suburbError }]}
            >
              <AutoComplete
                size="large"
                options={dataSource.map((item) => ({ value: item }))}
                onSelect={onSelect}
                onSearch={handleSearch}
                onClear={onClear}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                filterOption={(inputValue, option) =>
                  option?.value.toLowerCase().includes(inputValue.toLowerCase()) as boolean
                }
              >
                <Input size="large" maxLength={50} />
              </AutoComplete>
            </Form.Item>
            <Form.Item label="State" name="State" rules={[{ required: true, message: 'Please select a state' }]}>
              <Select
                size="large"
                showSearch
                placeholder="Select state"
                optionFilterProp="children"
                value={state1}
                onChange={(value) => setState1(value)}
                className="full-width"
              >
                {AUStateCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() => history.push(onBack)}
                loading={loadingStatus === OperationStatus.pending}
              >
                {buttonNames?.prevButton}
              </Button>
              <Button
                className="nextSenderBtn"
                size="large"
                type="primary"
                htmlType="submit"
                loading={loadingStatus === OperationStatus.pending}
              >
                {buttonNames?.nextButton}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
