import { Breadcrumb, Button, Col, DatePicker, Form, Row, Select, message } from 'antd';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { BookingActions } from '../store/BookingSlice';
import { BookingSelector } from '../store/BookingSelector';

import { SenderDetailsSelector } from '@features/SenderDetails';
import { ReceiverSelector } from '@features/Receiver';

import { Constants, Roles } from 'common/constants';
import { useEffect } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { TransactionSelector } from '@features/Transaction';
import { BoxDetailsModel } from 'common/models/BookingTypes';
import { UpdateBoxDetails } from '../business/BookingBusiness';
import dayjs from 'dayjs';
import { AccountSelector } from '@features/Account';
import { GetAllStore, StoresSelector } from '@features/Stores';
import { EmployeeGetAllAgent, EmployeeSelector } from '@features/Employee';
export const BoxDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeState = useSelector(StoresSelector);
  const bookingState = useSelector(BookingSelector);
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const receiverState = useSelector(ReceiverSelector);
  const accountState = useSelector(AccountSelector);
  const transactionState = useSelector(TransactionSelector);
  const employeeAgentState = useSelector(EmployeeSelector);
  useEffect(() => {
    if (!transactionState.FromUpdateLink) {
      if (
        !senderDetailsState.Sender === null ||
        senderDetailsState.Sender === undefined ||
        receiverState.Receiver === null ||
        receiverState.Receiver === undefined ||
        bookingState.BoxContent === null ||
        bookingState.BoxContent === undefined
      ) {
        history.push('/home');
      }
    }

    // alert(transactionState.TransactionSummary?.BoxDetails.Date);
  }, [bookingState, history]);

  useEffect(() => {
    if (accountState.RoleName != Roles.Customer) {
      const fetchData = async () => {
        if (!storeState.Stores || storeState.Stores.length == 0) {
          await dispatch(GetAllStore());
        }
      };

      fetchData();

      const fetchData1 = async () => {
        if (!employeeAgentState.Employee || employeeAgentState.Employee.length == 0) {
          await dispatch(EmployeeGetAllAgent());
        }
      };
      fetchData1();
    }
  }, []);
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const selectOptions = Array.from({ length: 20 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));
  const onFinish = async (values: any) => {
    const formattedDate = values.Date ? values.Date.format('YYYY-MM-DD') : null;
    // dispatch(
    //   BookingActions.SetBoxDetails({
    //     ...values,
    //   })
    // );

    const formattedValues = {
      ...values,
      Date: formattedDate,
    };
    dispatch(
      BookingActions.SetBoxDetails({
        ...formattedValues,
      })
    );
    if (transactionState.FromUpdateLink) {
      const boxDetailsInstance: BoxDetailsModel = {
        Id: transactionState.TransactionSummary?.BoxDetails.Id,
        TransactionsId: transactionState.TransactionSummary?.Transactions.Id,
        PickupBy: values.PickupBy,
        Date: formattedDate,
        NoOfBox: values.NoOfBox,
        Destination: values.Destination,
        Remarks: values.Remarks,
        StoreId: values.StoreId,
      };
      await dispatch(UpdateBoxDetails(boxDetailsInstance));
      message.success('Pick up and box details updated successfully');
      history.push('/transaction/summary');
    } else {
      if (
        bookingState.ReferralResponse?.IsFirstTransaction ||
        bookingState.ReferralResponse?.HasIncentiveForReferringSomeone
      ) {
        history.push('/booking/perks');
      } else {
        history.push('/booking/declaration');
      }
    }
  };

  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Pick up and box details</h1>
      </Row>

      <Form name="senderForm" onFinish={onFinish} layout="vertical">
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            {!transactionState.FromUpdateLink && (
              <Breadcrumb
                items={[
                  {
                    title: 'Sender',
                  },
                  {
                    title: 'Sender address',
                  },
                  {
                    title: 'Receiver',
                  },
                  {
                    title: 'Box content',
                  },
                  {
                    title: 'Pick up and box details',
                  },
                ]}
              />
            )}
            <br />
            {accountState.RoleName != Roles.Customer && (
              <Form.Item
                initialValue={
                  employeeAgentState?.Employee?.some(
                    (emp) =>
                      emp.UserId ===
                      (transactionState.FromUpdateLink
                        ? transactionState.TransactionSummary?.BoxDetails?.PickupBy
                        : bookingState.BoxDetails?.PickupBy)
                  )
                    ? transactionState.TransactionSummary?.BoxDetails.PickupBy
                    : undefined
                }
                label="Pickup by"
                name="PickupBy"
              >
                <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                  {employeeAgentState?.Employee?.map((emp) => (
                    <Select.Option key={emp.UserId} value={emp.UserId}>
                      {emp.FirstName} {emp.LastName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {accountState.RoleName != Roles.Customer && (
              <>
                <Form.Item
                  label="Pickup date"
                  name="Date"
                  initialValue={
                    transactionState.FromUpdateLink
                      ? transactionState.TransactionSummary?.BoxDetails?.Date
                        ? dayjs(transactionState.TransactionSummary.BoxDetails.Date)
                        : ''
                      : bookingState.BoxDetails?.Date
                      ? dayjs(bookingState.BoxDetails.Date)
                      : ''
                  }
                  rules={[
                    { required: transactionState.FromUpdateLink ? true : false, message: Constants.REQUIRED_FIELD },
                  ]}
                >
                  <DatePicker size="large" className="full-width" placeholder="Pickup date" format="YYYY-MM-DD" />
                </Form.Item>
              </>
            )}
            {accountState.RoleName != Roles.Customer && (
              <Form.Item
                initialValue={
                  !transactionState.FromUpdateLink
                    ? bookingState.BoxDetails?.StoreId
                    : (transactionState.TransactionSummary?.Stores || storeState.Stores)?.some(
                        (st) => st.Id === transactionState.TransactionSummary?.BoxDetails.StoreId
                      )
                    ? transactionState.TransactionSummary?.BoxDetails.StoreId
                    : undefined
                }
                label="Store"
                name="StoreId"
              >
                <Select size="large" showSearch placeholder="Select" optionFilterProp="children">
                  <Select.Option key="" value="">
                    {''}
                  </Select.Option>
                  {storeState.Stores?.map((s) => (
                    <Select.Option key={s.Id} value={s.Id}>
                      {s.Code} {s.Name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              initialValue={
                !transactionState.FromUpdateLink
                  ? bookingState.BoxDetails?.NoOfBox
                  : transactionState.TransactionSummary?.BoxDetails.NoOfBox
              }
              label="Number of boxes"
              name="NoOfBox"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                filterOption={filterOption}
                disabled={transactionState?.TransactionSummary?.Invoice?.UnitCount != null}
              >
                {selectOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
              initialValue={
                !transactionState.FromUpdateLink
                  ? bookingState.BoxDetails?.Destination
                  : transactionState.TransactionSummary?.BoxDetails.Destination
              }
              label="Destination"
              name="Destination"
              rules={[{ required: true, message: Constants.REQUIRED_FIELD }]}
            >
              <Select
                size="large"
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                filterOption={filterOption}
              >
                {DestinationCodeCodeList.map((destination) => (
                  <Select.Option key={destination.code} value={destination.code}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              initialValue={
                !transactionState.FromUpdateLink
                  ? bookingState.BoxDetails?.Remarks
                  : transactionState.TransactionSummary?.BoxDetails.Remarks
              }
              label="Delivery instructions (optional)"
              name="Remarks"
            >
              <TextArea size="large" />
            </Form.Item>
            <div>
              <Button
                className="prevSenderBtn"
                size="large"
                type="default"
                onClick={() =>
                  history.push(!transactionState.FromUpdateLink ? '/booking/boxcontent' : '/transaction/summary')
                }
              >
                {Constants.BTN_BACK}
              </Button>
              <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
                {transactionState.FromUpdateLink ? Constants.BTN_UPDATE : Constants.BTN_CONTINUE}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
