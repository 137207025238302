import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space, Spin, Tag, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styles from 'features/SenderDetails/Sender.module.less';
import { Constants, SenderStatus } from 'common/constants';
import { SenderModel } from 'common/models/BookingTypes';
import { useDispatch, useSelector } from 'react-redux';
import { SenderDetailsActions } from '@features/SenderDetails/store/SenderDetailsSlice';
import { BookingSelector } from '@features/Booking';
import { GetAllSender, GetSenderById, SenderDetailsSelector } from '@features/SenderDetails';
import { PATHS } from '@routing/routes';
import { TransactionActions } from '@features/Transaction';
import { commonSelector } from '@features/Common/store/commonSelector';
import { commonAction } from '@features/Common/store/commonSlice';
import { UpdateTransactionOrderABoxSender } from '../business/OrderBoxBusiness';
import { EmployeeGetAllAgent, EmployeeSelector } from '@features/Employee';
const { CheckableTag } = Tag;

export const SenderListingOrderBox = () => {
  const senderDetailsState = useSelector(SenderDetailsSelector);
  const bookingState = useSelector(BookingSelector);
  const commonState = useSelector(commonSelector);
  const employeeAgentState = useSelector(EmployeeSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleChange = (userId: string) => {
    dispatch(commonAction.SetGlobalSenderId(userId));
  };

  const [data, setData] = useState<SenderModel[]>([]);

  useEffect(() => {
    // dispatch(TransactionActions.SetFromUpdateLinkOrderABox());
    const fetchDataAsync = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const result = await GetAllSender(commonState.GlobalSenderUserId);
        if (result && result.length > 0) {
          if (result[0]?.Address == null) {
            await dispatch(GetSenderById(result[0]?.Id));
            dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.Update));

            dispatch(SenderDetailsActions.SetFirstSender(true));
            history.push(PATHS.OrderBoxAddress.path);
          }
        }
        setData(result);
        // alert(commonState.GlobalSenderId);
        if (commonState.GlobalSenderId == undefined) {
          dispatch(commonAction.SetGlobalSenderId(result[0]?.Id));
        }
      } catch (error) {
        // Handle error as needed
      }
    };
    fetchDataAsync();

    const fetchData1 = async () => {
      if (!employeeAgentState.Employee) {
        await dispatch(EmployeeGetAllAgent());
      }
    };
    fetchData1();
  }, []);

  const onUpdate = async (value: any) => {
    if (value === null) return;
    await dispatch(GetSenderById(value));
    dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.Update));
    dispatch(SenderDetailsActions.SetSelectedId(value));
    dispatch(commonAction.SetGlobalSenderId(value));
    history.push(PATHS.OrderEmtpyBox.path);
  };

  const AddNewSender = async () => {
    dispatch(SenderDetailsActions.resetState());
    dispatch(SenderDetailsActions.SetSenderStatus(SenderStatus.New));
  };

  const onFinish = async () => {
    if (commonState?.GlobalSenderId === undefined) {
      message.warning('Please add a sender for this transaction.');
      return;
    }

    if (commonState.GlobalTransactionId) {
      await dispatch(
        UpdateTransactionOrderABoxSender({
          Id: commonState.GlobalTransactionId,
          SenderId: commonState?.GlobalSenderId,
        })
      );
      message.success('Sender updated successfully');
      history.push(PATHS.SummaryOrderBox.path);
    } else {
      history.push(PATHS.OrderBox.path);
    }
  };
  return (
    <div className="App">
      <Form name="senderForm" layout="vertical" onFinish={onFinish}>
        <Row justify="center" align="middle">
          <h1>Order an empty box</h1>
        </Row>
        {data ? (
          <Row justify="center" align="middle">
            <Col>
              <Space wrap direction="vertical">
                {data.map((sender) => (
                  <div key={sender.Id} className={styles.FlexColumnContainer}>
                    <Row justify="center" align="middle">
                      <Col span={24} className={`${styles.rightText}`}>
                        <a className={styles.link} onClick={() => onUpdate(sender.Id)}>
                          Update
                        </a>
                      </Col>

                      <Col span={24}>
                        <CheckableTag
                          checked={sender.Id === commonState.GlobalSenderId}
                          onChange={() => handleChange(sender.Id)}
                          className={styles.anttagcheckable}
                        >
                          <div>
                            <div>
                              {sender.GivenName} {sender.Surname}
                            </div>
                            <div>
                              {sender.Address}, {sender.Suburb}, {sender.State}, {sender.PostalCode}
                            </div>
                            <div>{sender.Email}</div>
                            <div>
                              {sender.MobileCode} {sender.ContactNo}
                            </div>
                          </div>
                        </CheckableTag>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Space>
            </Col>
          </Row>
        ) : (
          <Spin size="large" />
        )}
        <Row justify="center" align="middle">
          <Col>
            <br />
            <Link to={PATHS.OrderEmtpyBox.path} className="normal-font" onClick={AddNewSender}>
              Add new address
            </Link>
          </Col>
        </Row>
        <br />
        <Row justify="center" align="middle">
          <Col xs={24} sm={8} md={14} lg={10} xl={7} xxl={5}>
            <Button
              className="prevSenderBtn"
              size="large"
              type="default"
              onClick={() =>
                commonState.GlobalTransactionId
                  ? history.push(PATHS.SummaryOrderBox.path)
                  : history.push(PATHS.Home.path)
              }
            >
              {commonState.GlobalTransactionId ? Constants.BTN_BACK : Constants.BTN_CLOSE}
            </Button>
            <Button className="nextSenderBtn" size="large" type="primary" htmlType="submit">
              {Constants.BTN_CONTINUE}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
