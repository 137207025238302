import { Button, Card, Checkbox, Col, Form, Modal, Row, Table, Typography, message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteBooking,
  GetRemarks,
  GetTransactionById,
  SaveRemarks,
  UpdateOrderBox,
} from '../business/TransactionBusiness';
import { TransactionSelector } from '../store/TransactionSelector';
import { Link, useHistory } from 'react-router-dom';
import { DestinationCodeCodeList } from 'common/code/DestinationCode';
import { Constants, PaymentStatus, Roles, TransactionBoxStatus, TransactionType } from 'common/constants';
import { formatTwoDecimalPlaces } from 'common/utils/utility';
import { TransactionActions } from '../store/TransactionSlice';
import dayjs from 'dayjs';
import { TransStatusDisplayViewModel } from 'common/models/BookingTypes';
import { AccountSelector } from '@features/Account';
import { ReceiverActions } from '@features/Receiver';
import { getPaymentTypeNameByCode } from 'common/code/BookingCode';
const { Text } = Typography;
import { ExclamationCircleFilled } from '@ant-design/icons';
import { OperationStatus } from 'store/rootTypes';
import '@features/Transaction/Transaction.css';
import { PATHS } from '@routing/routes';
import { BoxOrderViewModel } from 'common/models';
import { commonAction } from '@features/Common/store/commonSlice';
import { EmployeeGetAllAgent, EmployeeSelector } from '@features/Employee';
import { RemarksResponse } from 'common/models/RemarksTypes';
import TextArea from 'antd/es/input/TextArea';
export const SummaryOrderBox = () => {
  const [form] = Form.useForm(); // Initialize the form
  const dispatch = useDispatch();
  const history = useHistory();
  const accountState = useSelector(AccountSelector);
  const transactionState = useSelector(TransactionSelector);
  const employeeAgentState = useSelector(EmployeeSelector);
  let data: TransStatusDisplayViewModel[] = transactionState.TransactionSummary?.TransStatusDisplay || [];
  let remarksData: RemarksResponse[] = transactionState.TransactionRemarks || [];
  useEffect(() => {
    const fetchData = async () => {
      if (transactionState.SelectedTransId) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        await dispatch(GetTransactionById(transactionState.SelectedTransId));
        data = transactionState.TransactionSummary?.TransStatusDisplay || [];

        await dispatch(GetRemarks(transactionState.SelectedTransId));
        remarksData = transactionState.TransactionRemarks || [];
      }
    };
    dispatch(TransactionActions.SetFromUpdateLinkFalse());
    fetchData();

    const fetchData1 = async () => {
      if (!employeeAgentState.Employee) {
        await dispatch(EmployeeGetAllAgent());
      }
    };
    fetchData1();
  }, [transactionState?.SelectedTransId]);

  const onFinish = async (values: any) => {
    dispatch(TransactionActions.SetFromUpdateLinkFalse());
    if (transactionState.TransactionSummary?.Receiver.Id == Constants.NoSelectedReceiver) {
      message.error('Please select or update receiver first.');
      return;
    }
  };

  const onDeliveredCancel = async (status: string) => {
    if (transactionState.SelectedTransId) {
      const requestData: BoxOrderViewModel = {
        Id: transactionState.TransactionSummary?.BoxOrder.Id,
        TransactionsId: transactionState.SelectedTransId,
        Status: status,
        Date: transactionState.TransactionSummary?.BoxOrder.Date,
      };
      await dispatch(UpdateOrderBox(requestData));
      await dispatch(GetTransactionById(transactionState.SelectedTransId));
      data = transactionState.TransactionSummary?.TransStatusDisplay || [];
    }
  };

  const onBack = () => {
    dispatch(TransactionActions.SetSelectedTransId(undefined));
    history.push('/transaction');
  };
  const handleUpdateClickBox = () => {
    dispatch(TransactionActions.SetFromUpdateLink());
    dispatch(TransactionActions.SetFromUpdateLinkOrderABox());
  };

  const handleUpdateClick = (userId?: string, transactionId?: string, path?: string, senderId?: string) => {
    if (userId && transactionId && path && senderId) {
      dispatch(commonAction.SetGlobalSenderUserId(userId));
      dispatch(commonAction.SetGlobalTransactionId(transactionId));
      dispatch(commonAction.SetGlobalSenderId(senderId));
      history.push(path);
    }
  };

  const hideUpdate = () => {
    // if (accountState.RoleName != Roles.Customer) {
    //   return true;
    // }

    if (accountState.RoleName != Roles.Customer) {
      return true;
    }

    if (
      transactionState?.TransactionSummary?.TransactionStatus?.length &&
      transactionState.TransactionSummary.TransactionStatus.length < 2
    ) {
      return true;
    }
    return false;
  };
  const columns = [
    // {
    //   title: 'Date',
    //   dataIndex: 'CreatedDate',
    //   key: 'CreatedDate',
    // },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text, record) => {
        return (
          <>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </>
        );
      },
    },
  ];

  const { confirm } = Modal;

  const showConfirm = () => {
    //Shift yes or no to satisfy the req. will refactor next time
    confirm({
      title: 'Do you want to delete this booking?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'No',
      cancelText: 'Yes',
      okButtonProps: { className: 'ant-btn-default-okcancelbooking' },
      cancelButtonProps: { className: 'ant-btn-primary' },
      async onCancel() {
        if (transactionState.SelectedTransId) {
          await dispatch(DeleteBooking(transactionState.SelectedTransId));
          await dispatch(TransactionActions.SetSelectedTransId(undefined));
          history.push('/booking/listing');
        }
      },
      async onOk() {
        //conso
      },
    });
  };

  const [remarks, setRemarks] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the input
    if (!remarks) {
      return;
    }

    if (transactionState.SelectedTransId) {
      await dispatch(
        SaveRemarks({
          TransactionsId: transactionState.SelectedTransId,
          Details: remarks,
        })
      );
    }

    setRemarks('');
    form.setFieldsValue({ Remarks: '' });
  };
  return (
    <div className="App">
      <Row justify="center" align="middle">
        {' '}
        <h1>Transaction summary - empty box order</h1>
      </Row>
      <Form name="senderForm" onFinish={onFinish} layout="vertical" form={form}>
        <Row justify="center" align="middle">
          <Col xs={24} sm={20} md={12} lg={8} xl={8}>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text className="">Transaction</Text>
                </Col>
                {data.length >= 1 && data.length <= 2 && accountState.RoleName !== Roles.Customer && (
                  <Col span={12} className="right-text">
                    <a className="normal-font" onClick={showConfirm}>
                      Cancel
                    </a>
                  </Col>
                )}
                {data.length === 1 && accountState.RoleName == Roles.Customer && (
                  <Col span={12} className="right-text">
                    <a className="normal-font" onClick={showConfirm}>
                      Cancel
                    </a>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>Reference no: {transactionState.TransactionSummary?.Transactions.RefNo}</Text>
                    <br />
                    <Text>Status - {data[data.length - 1]?.Status}</Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text className="">Sender</Text>
                </Col>
                {hideUpdate() && (
                  <Col span={12} className="right-text">
                    <a
                      className="normal-font"
                      onClick={() =>
                        handleUpdateClick(
                          transactionState.TransactionSummary?.Sender.UserId,
                          transactionState.TransactionSummary?.Transactions.Id,
                          PATHS.SenderListingOrderBox.path,
                          transactionState.TransactionSummary?.Sender.Id
                        )
                      }
                    >
                      Update
                    </a>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.GivenName}{' '}
                      {transactionState.TransactionSummary?.Sender.Surname}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.Address},{' '}
                      {transactionState.TransactionSummary?.Sender.Suburb},{' '}
                      {transactionState.TransactionSummary?.Sender.State},{' '}
                      {transactionState.TransactionSummary?.Sender.PostalCode}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>{transactionState.TransactionSummary?.Sender.Email}</Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.Sender.MobileCode}{' '}
                      {transactionState.TransactionSummary?.Sender.ContactNo}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={12}>
                  <Text>Box order details</Text>
                </Col>
                {hideUpdate() && (
                  <Col span={12} className="right-text">
                    <Link to={PATHS.OrderBox.path} className="normal-font" onClick={handleUpdateClickBox}>
                      Update
                    </Link>
                  </Col>
                )}
                <Card className="card-custom">
                  <Col span={24}>
                    <Text>
                      {transactionState.TransactionSummary?.BoxOrder?.Date && (
                        <>
                          Drop off date:{' '}
                          {dayjs(transactionState.TransactionSummary?.BoxOrder.Date).format('YYYY-MM-DD')}
                          <br />
                        </>
                      )}
                      {transactionState.TransactionSummary?.BoxOrder?.Quantity}{' '}
                      {transactionState.TransactionSummary?.BoxOrder?.Quantity &&
                      transactionState.TransactionSummary?.BoxOrder?.Quantity > 1
                        ? 'boxes'
                        : 'box'}{' '}
                      {/* going to{' '} */}
                      {transactionState.TransactionSummary?.BoxOrder?.Remarks && (
                        <>
                          <br />
                          {transactionState.TransactionSummary?.BoxOrder.Remarks}
                        </>
                      )}
                      {transactionState.TransactionSummary?.BoxOrder?.PickupBy &&
                        transactionState.TransactionSummary?.BoxOrder?.PickupName &&
                        accountState.RoleName != Roles.Customer && (
                          <>
                            <br />
                            Agent: {transactionState.TransactionSummary?.BoxOrder?.PickupName}
                          </>
                        )}
                    </Text>
                  </Col>
                </Card>
              </Row>
            </Form.Item>
            {accountState.RoleName != Roles.Customer && (
              <>
                <Form.Item className="mb-5">
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Add note" name="Remarks" className="mb-5">
                        <TextArea size="large" onChange={(e) => setRemarks(e.target.value)} value={remarks} />
                      </Form.Item>
                      <Button
                        className="nextSenderBtn3"
                        size="middle"
                        type="primary"
                        htmlType="button"
                        onClick={handleSubmit}
                      >
                        {Constants.BTN_ADD}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
                {remarksData && remarksData.length > 0 && (
                  <Form.Item>
                    <Row>
                      <Col span={24}>
                        <label>Notes</label>
                        <Card className="card-custom lightgrayF9">
                          <Col span={24}>
                            {remarksData?.map((result, index) => (
                              <p
                                key={index}
                                dangerouslySetInnerHTML={{ __html: result.Details }}
                                className="mtp-0 mb-5"
                              ></p>
                            ))}
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Form.Item>
                )}
              </>
            )}
            <Table className="table-summary" dataSource={data} columns={columns} pagination={false} />
            <br />
            {accountState.RoleName != Roles.Customer && (
              <div>
                <>
                  {!data.some((item) => item.Code === TransactionBoxStatus.DE) && (
                    <Button
                      className="nextSenderBtn"
                      size="large"
                      type="primary"
                      onClick={() => onDeliveredCancel(TransactionBoxStatus.DE)}
                    >
                      {Constants.BTN_DELIVERED}
                    </Button>
                  )}
                  <Button
                    className={`prevSenderBtn ${
                      data.some((item) => item.Code === TransactionBoxStatus.DE) ? 'full-width' : ''
                    }`}
                    size="large"
                    type="default"
                    onClick={() => onBack()}
                  >
                    {Constants.BTN_BACK}
                  </Button>
                </>
              </div>
            )}
            {accountState.RoleName == Roles.Customer && (
              <>
                {/* {data.length === 1 && (
                  <Button
                    loading={transactionState.submitStatus === OperationStatus.pending}
                    className="prevSenderBtn2"
                    size="large"
                    type="default"
                    onClick={showConfirm}
                  >
                    {Constants.BTN_CANCEL_BOOKING}
                  </Button>
                )}{' '} */}
                <Button
                  className="prevSenderBtn2 full-width"
                  size="large"
                  type="default"
                  onClick={() => history.push('/booking/listing')}
                >
                  {Constants.BTN_BACK}
                </Button>{' '}
              </>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};
